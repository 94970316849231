







import { View } from 'client-website-ts-library/plugins';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class PropertyReportPreview extends Mixins(View) {
  mounted() {
    let scr: HTMLScriptElement | null = document.getElementById('scrCoreLogicIngegration') as HTMLScriptElement | null;
    if (scr) scr.remove();

    setTimeout(() => {
      scr = document.createElement('script');
      scr.src = 'https://dpr.leadplus.com.au/main.js';
      scr.async = true;
      scr.defer = true;
      scr.id = 'scrCoreLogicIngegration';

      scr.onload = () => {
        console.log('Core Logic Integration Loaded');
      };

      document.body.append(scr);
    }, 500);

    const intervalId = setTimeout(() => {
      console.log('interval running');
      const daIntegration = document.getElementById('daintegration')!;
      if (daIntegration) {
        clearInterval(intervalId);
        console.log('Da integration found');
        const shadowRoot = document.getElementById('daintegration')!.shadowRoot!;
        const nextButton = shadowRoot.getElementById('stepFormNextBtn')!;

        nextButton.addEventListener('click', () => {
          const form = shadowRoot.querySelectorAll('form')[0];
          const firstName = form.querySelectorAll('#da_lead_form_given_name')[0] as HTMLInputElement;
          const lastName = form.querySelectorAll('#da_lead_form_family_name')[0] as HTMLInputElement;
          this.$root.$data.coreLogicFormData.firstName = firstName.value;
          this.$root.$data.coreLogicFormData.lastName = lastName.value;

          setTimeout(() => {
            // step 3 - /property-report-preview
            nextButton.addEventListener('click', () => {
              const email = form.querySelectorAll('#da_lead_form_email')[0] as HTMLInputElement;
              const phone = form.querySelectorAll('#da_lead_form_phone')[0] as HTMLInputElement;
              this.$root.$data.coreLogicFormData.email = email.value;
              this.$root.$data.coreLogicFormData.phone = phone.value;

              setTimeout(() => {
                // step 4 - /property-report-preview
                nextButton.addEventListener('click', () => {
                  const formRadioButtons = form.querySelectorAll('.da-radios')[0];

                  let reportPurpose;
                  if (formRadioButtons.querySelectorAll('input')[0].checked) {
                    reportPurpose = 'Rent out my property';
                  } else if (formRadioButtons.querySelectorAll('input')[1].checked) {
                    reportPurpose = 'Maximise returns for my investment property';
                  } else if (formRadioButtons.querySelectorAll('input')[2].checked) {
                    reportPurpose = 'Buy an investment property';
                  } else if (formRadioButtons.querySelectorAll('input')[3].checked) {
                    reportPurpose = 'Research rental market conditions';
                  }

                  this.$root.$data.coreLogicFormData.reportPurpose = reportPurpose;

                  // send data to prolist api
                  console.log('coreLogicData: ', this.$root.$data.coreLogicFormData);
                });
              }, 1000);
            });
          }, 1000);
        });
      }
    }, 500);
  }
}
